<template>
  <div>
    <header-main
        title="Предоставление сведений о перечне учебных изданий, указанный в рабочих программах дисциплин (модулей) по истории (истории России, всеобщей истории)"/>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
          :initialOpen="true"
          :title="'Ответственный за заполнение сбора'"
      >
        <ContainerPersonContact
            :blocked="getBlock"
            :can-be-deleted="false"
            :contact="contact"
            :loading-contacts="savingContact"
            :saving-data="savingContact"
            @change-contact="changeContact($event,0,contact)"
            is-single
        />
      </WrapperCollapse>
    </WrapperPreviewCategory>

    <Alert alert-type="success">
      <template #icon>
        <v-icon color="#29A128" size="40">mdi-check-bold</v-icon>
      </template>
      <template #default>
        <div class="d-flex flex-row justify-space-between">
          <p class="d-flex align-center">{{
              getBlock ? 'Ввод данных заблокирован' :
                  'Заблокировать ввод данных'
            }}</p>

          <v-btn
              :disabled="getBlock"
              :loading="loadingBlockEnterData"
              @click="blockOrg()"
              class="success__v-btn pa-3"
              color="#29A128"
          >
            <span style="color:#fff;">{{ getBlock ? 'Заблокировано' : 'Заблокировать ввод' }}</span>
          </v-btn>
        </div>
      </template>
    </Alert>

    <WrapperPreviewCategory customClass="section-preview_lk">
      <div class="d-flex justify-end">
        <v-btn
            :disabled="getBlock"
            @click="openDialog('add')"
            class="blue__v-btn mb-2"
        >
          Добавить
        </v-btn>
      </div>
      <GridTable
          :data-rows="tableData"
          :headers="tableHeaders.massive"
          :headers-for-rows="tableHeaders.massive"
          :loading-data="loadingData"
          :num-cols="tableHeaders.countCells"
          max-table-height="700px"
      >
        <template #actions="{row}">
          <div class="d-flex align-center justify-center flex-row">
            <v-btn :disabled="getBlock" :loading="loadingEdit.id === row.id && loadingEdit.value"
                   @click.stop="openDialog('edit', row)"
                   icon>
              <v-icon color="primary" size="24">mdi-pencil</v-icon>
            </v-btn>
            <v-btn :disabled="getBlock" :loading="loadingDelete.id === row.id && loadingDelete.value"
                   @click.stop="deleteRow(row)"
                   icon>
              <v-icon color="primary" size="24">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </GridTable>
    </WrapperPreviewCategory>
    <universal-dialog
        ref="dialog"
        :data-for-dialog="dataForDialog"
        :headers-for-dialog="tableHeaders.dialog"
        :spr-data="sprDataForDialog"
        @fetch-new-data="changeData($event)"
        @dialog-emit="filterFunc($event)"
        :loading="savingData"
        @closing-dialog="clearDataForDialog()"
    >
      <template #title>
        <h2>Добавить запись</h2>
      </template>
      <template #actions="{close, sendData, loading, validSolo}">
        <div class="d-flex flex-row align-center justify-space-between" style="width: 100%">
          <v-btn
              class='white__v-btn'
              @click="close()"
          >
            Отменить
          </v-btn>
          <v-btn
              class='blue__v-btn'
              @click="sendData()"
              :loading="loading"
              :disabled="!validSolo"
          >
            Сохранить
          </v-btn>
        </div>
      </template>
    </universal-dialog>
  </div>
</template>

<script>
import {mapSpr} from "@/utils/helpers";
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import HeaderMain from "@/components/headers/HeaderMain";
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";
import Alert from "@/components/UI/Alert";
import GridTable from "@/components/GridTable/GridTable";
import Sbor2TableHeaders from "@/tableJson/sbor2TableHeaders";
import universalDialog from "@/components/Dialogs/universalDialog";

export default {
  name: "LK",
  components: {
    GridTable,
    Alert,
    HeaderMain,
    WrapperPreviewCategory,
    WrapperCollapse,
    ContainerPersonContact,
    universalDialog
  },
  data() {
    return {
      contact: {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        extensionNumber: '',
        personType: 'HEAD'
      },
      loadingData: false,
      savingData: false,
      loadingEdit: {value: false, id: null},
      loadingDelete: {value: false, id: null},
      tableData: [],
      tableHeaders: Sbor2TableHeaders,
      loadingContacts: false,
      savingContact: false,
      loadingContact: false,
      errorSaving: null,
      loadingBlockEnterData: false,
      loadingBlockEnterWithoutData: false,
      downloadingFileForPrint: false,
      sprOrg: [],
      block: false,
      loadingBlockStatus: false,
      sprOrgLoading: false,
      sprOrgError: false,
      sprSpec: [],
      sprSpecLoading: false,
      sprSpecError: false,
      dataForDialog: null,
    }
  },
  created() {

    if (this.authData && this.getOrgID)
    {
      this.getUserInfo();
      this.getBlockStatus();
    }
    this.getContacts();
    this.getData();
    this.loadSprOrg();
    this.loadSprSpec();
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {authToken: 'authToken'}),
    ...mapGetters('auth', {authStatus: 'authStatus'}),
    ...mapGetters('checkFilled', {get_check_filled: 'get_check_filled'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
    ...mapGetters('auth', {getAuthUserData: 'getAuthUserData'}),
    ...mapGetters('auth', {getAuthUserDataLoading: 'getAuthUserDataLoading'}),
    ...mapGetters('auth', {getAuthUserDataError: 'getAuthUserDataError'}),
    getBlock() {
      return this.block || this.loadingBlockStatus;
    },
    getOrgID() {
      if (this.$route.query?.orgID) {
        return this.$route.query.orgID;
      }
      return Number.parseInt(this.authData?.orgID ?? this.authData?.family_name);
    },
    getOrgFounderID() {
      return Number.parseInt(this.getAuthUserData?.founderId);
    },
    sprDataForDialog() {
      let ret = {
        sprOrg: this.getSprOrg(),
        sprSpec: this.getSprSpec(),
      }
      return ret
    },
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    ...mapActions('auth', {blockEnterData: 'blockEnterData'}),
    ...mapActions('auth', {getUserInfo: 'getUserInfo'}),
    ...mapActions('auth', {blockOrganization: 'blockOrganization'}),
    getQuery(item) {
      return {
        path: item.href,
        query: {orgID: this.$route.query.orgID}
      }
    },
    async getBlockStatus(){
      this.loadingBlockStatus = true
      try {
        const res = await request({
          endpoint: `/api/sbor2/block/${this.getOrgID}`,
          method: 'get'
        })
        if (res.status === 200) {
          if (res.data) {
            this.block = res.data.block
          }
        }
        this.loadingBlockStatus = false
      }catch (e) {
        console.error('error get block status', e)
        this.loadingBlockStatus = false
      }
    },
    async blockOrg(){
      this.loadingBlockStatus = true
      try {
        const res = await request({
          endpoint: `/api/sbor2/block/${this.getOrgID}/${this.block ? 'unblock' : 'block'}`,
          method: 'get'
        })
        if (res.status === 200) {
          if (res.data) {
            this.block = res.data.block
          }
        }
        this.loadingBlockStatus = false
      }catch (e) {
        console.error('error to  block org', e)
        this.loadingBlockStatus = false
      }
    },
    async loadSprOrg() {
      this.sprOrgLoading = true
      const res = await request({
        endpoint: `/api/sbor2/organization/${this.getOrgID}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprOrg = res.data
        }
      }
      this.sprOrgLoading = false
    },
    async loadSprSpec() {
      this.sprSpecLoading = true
      const res = await request({
        endpoint: `/api/sbor2/spr/spec`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprSpec = res.data
        }
      }
      this.sprSpecLoading = false
    },
    async clickBlockEnterData() {
      this.loadingContacts = true;
      const res = await request({
        endpoint: `/api/sbor2/organization/${this.getOrgID}/block`,
        method: 'get'
      })
      if (res.status === 200) {
        this.blockOrganization(res.data)
      }
      this.loadingContacts = false;
    },
    async getData() {
      this.loadingData = true;
      const res = await request({
        endpoint: `/api/sbor2/data/${this.getOrgID}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.tableData = res.data
        }
      }
      this.loadingData = false;
    },
    async changeData(row) {
      this.savingData = true;

      // console.log(row, this.getOrgID);
      const res = await request({
        endpoint: `/api/sbor2/data/${row?.id ? 'update' : 'new'}`,
        method: row?.id ? 'post' : 'post',
        body: {
          ...row
        },
      })
      if (res.status === 200) {
        if (row.id) {
          const index = this.tableData.findIndex(el => el.id === row.id)
          this.tableData.splice(index, 1, res.data) //res.data
        } else {
          this.tableData.push(res.data); //res.data
        }
        setTimeout(() => {
          this.$refs.dialog.closeDialog();
          this.savingData = false;
        }, 2000);
      } else {
        this.savingData = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ',
          timeout: 5000
        })
      }
    },
    async getContacts() {
      this.loadingContacts = true;
      const res = await request({
        endpoint: `/api/sbor2/contact/${this.getOrgID}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.contact = res.data
          await this.fetchCheckFilled({headContact: this.contact, contacts: this.contact})
        }
      }
      this.loadingContacts = false;
    },
    async changeContact(obj) {
      this.savingContact = true;

      // console.log(obj, this.getOrgID);
      const res = await request({
        endpoint: `/api/sbor2/contact/${obj?.id ? 'update' : 'new'}`,
        method: obj?.id ? 'put' : 'post',
        body: {
          ...obj,
          orgId: this.getOrgID
        },
      })
      if (res.status === 200) {
        // console.log(this.savingContact);
        this.contact = res.data;
        setTimeout(() => {
          this.savingContact = false;
        }, 2000);
      } else {
        this.savingContact = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного ',
          timeout: 5000
        })
      }
    },
    async openDialog(type = 'action', row = null) {
      if (type === 'edit') {
        this.dataForDialog = row
        await this.$refs.dialog.openDialog()
      } else {
        // eslint-disable-next-line no-unused-vars
        const res = this.$refs.dialog.openDialog()
      }
    },
    clearDataForDialog() {
      this.dataForDialog = null;
    },
    getSprOrg() {
      let data = [];
      if (!this.sprOrgLoading && !this.sprOrgError) {
        data = mapSpr(this.sprOrg, 'id', (el) => {
          return el.orgName
        })
      }
      return {data: data, loading: this.sprOrgLoading, error: this.sprOrgError}
    },
    getSprSpec() {
      let data = [];
      if (!this.sprSpecLoading && !this.sprSpecError) {
        data = mapSpr(this.sprSpec, 'specId', (el) => {
          return el.specCode + " - " + el.specName
        })
      }
      return {data: data, loading: this.sprSpecLoading, error: this.sprSpecError}
    },
    async deleteRow(row) {
      const agree = confirm('Удалить запись?')
      if (agree) {
        this.loadingDelete = {value: true, id: row.id};
        try {
          const res = await request({
            endpoint: `/api/sbor2/data/delete/${row.id}`,
            method: 'get',
          })
          if (res.status === 200) {
            this.loadingDelete = {value: true, id: row.id};
            const index = this.tableData.findIndex(el => el.id === row.id)
            this.tableData.splice(index, 1)
          } else {
            this.loadingDelete = {value: false, id: row.id};
            await this.addNotification({
              notificationStatus: 'error',
              notificationMessage: 'Ошибка удаления',
              timeout: 3000
            });
          }
        } catch (e) {
          console.log("e delete", e)
          this.loadingDelete = {value: false, id: row.id};
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка удаления',
            timeout: 3000
          });

        }
      }
    },
  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06)
  //border: 1px solid #cdd1d4
  margin-bottom: 20px


  .lk-list_flex_start
    justify-content: flex-start
</style>