<template>
  <div>
    <div><router-link to="/" class="link-toMain">На главную</router-link></div>
    <l-k-admin v-if="isAdmin"></l-k-admin>
    <l-k v-else></l-k>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import LK from "./LK/LK";
import LKAdmin from "./LK/LKAdmin";

export default {
  name: "ViewLK",
  components: {LKAdmin, LK},
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {getAuthUserData: 'getAuthUserData'}),
    ...mapGetters('auth', {getAuthUserDataError: 'getAuthUserDataError'}),
    ...mapGetters('auth', {getAuthUserDataLoading: 'getAuthUserDataLoading'}),
    isAdmin() {
      if (this.authData.realm_access.roles.find(el => el === 'ADMIN_ROLE')) {
        return true;
      }
      // if(!this.getAuthUserDataError && !this.getAuthUserDataLoading)
      //     return this.getAuthUserData.userProfile;
      return false;
    }
  },
}
</script>

<style scoped>

</style>