export default {
    countCells: 10,
    massive: [
        {
            dataField: 'orgName',
            cols: 3,
            rows: 2,
            text: 'Наименование образовательной организации высшего образования/ филиал',
            order: 0,
            variable: true,
            template: (row) => {
                return row['orgName']
            }
        },
        {
            dataField: 'specCode',
            cols: 1,
            rows: 2,
            text: 'Код направления подготовки/ специальности',
            order: 1,
            variable: true,
        },
        {
            dataField: 'specName',
            cols: 1,
            rows: 2,
            text: 'Код направления подготовки/ специальности',
            order: 2,
            variable: true,
        },
        {
            dataField: 'title1',
            cols: 4,
            rows: 1,
            text: 'Перечень учебных изданий, указанный в рабочих программах дисциплин (модулей) по истории (истории России, всеобщей истории)',
            order: 3,
            variable: false,
        },
        {
            dataField: 'actions',
            cols: 1,
            rows: 2,
            text: 'Действия',
            order: 7,
            variable: true,
        },
        {
            dataField: 'authors',
            cols: 1,
            rows: 1,
            text: 'Автор(ы)',
            order: 3,
            variable: true,
        },
        {
            dataField: 'bookName',
            cols: 1,
            rows: 1,
            text: 'Наименование учебного издания',
            order: 4,
            variable: true,
        },
        {
            dataField: 'years',
            cols: 1,
            rows: 1,
            text: 'Год издания',
            order: 5,
            variable: true,
        },
        {
            dataField: 'publisher',
            cols: 1,
            rows: 1,
            text: 'Издательство',
            order: 6,
            variable: true,
        },
    ],
    dialog: [
        {
            text: 'Наименование организации',
            dataField: "orgId",
            variableName: 'orgId',
            sprDataName: 'sprOrg',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 0,
        },
        {
            text: 'Направление подготовки (специальность)',
            dataField: "specId",
            variableName: 'specId',
            sprDataName: 'sprSpec',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 2,
        },
        {
            text: "Автор(ы)",
            dataField: "authors",
            typeVar: "string",
            rules: 3,
            order: 3,
        },
        {
            text: "Наименование учебного издания",
            dataField: "bookName",
            typeVar: "string",
            rules: 3,
            order: 4,
        },
        {
            text: "Год издания",
            dataField: "years",
            typeVar: "string",
            rules: 3,
            order: 5,
        },
        {
            text: "Издательство",
            dataField: "publisher",
            typeVar: "string",
            rules: 3,
            order: 6,
        },
    ]
}


/*




 */